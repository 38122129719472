tns({
  container: '.container-images-mobile',
  items: 1,
  slideBy: "page",
  speed: 400,
  autoplay: true,
  nav: true,
  controls: false,
  navPosition: 'bottom',
  autoHeight: true
});

